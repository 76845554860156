
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, setToken } from './redux/authSlice';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthForm from './form/AuthForm';
import Home from './Home';
import Return from './Return';
import CheckoutForm from './StripeCheckout.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Hospitals from './Hospital.js';
import PrivateRoute from './PrivateRoute';
import { usePostHog } from 'posthog-js/react'

const theme = createTheme();

function App() {
  const dispatch = useDispatch();
  const posthog = usePostHog()
  const token = useSelector((state) => state.auth.token);
  const matches = useMediaQuery('(min-width:800px)');

  useEffect(() => {
    const authToken = localStorage.getItem('auth_token');
    if (authToken) {
      dispatch(setToken(authToken));
      dispatch(fetchUser());
    }
  }, [dispatch]);

  if (!matches) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        padding="20px"
        textAlign="center"
      >
        <Typography variant="h4" gutterBottom>
          We noticed you're trying to access it on a mobile device
        </Typography>
        <Typography variant="body1">
          Our app is designed for larger screens, with a minimum width of 1440 pixels. For the best experience, we recommend using a desktop computer or a larger tablet.
        </Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/login" element={token ? <Navigate to="/home" /> : <AuthForm mode="login" />} />
          <Route path="/register" element={token ? <Navigate to="/home" /> : <AuthForm mode="register" />} />
          {/* <Route path="/" element={<Navigate to={token ? "/home" : "/login"} />} /> */}
          <Route path="/" element={<Navigate to={token ? "/home" : "/home"} />} />

          <Route 
            path="/home" 
            element={
              // <PrivateRoute>
                <Home />
              // </PrivateRoute>
            } 
          />
          <Route 
            path="/checkout" 
            element={
              <PrivateRoute>
                <CheckoutForm />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/return" 
            element={
              <PrivateRoute>
                <Return />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/hospitals" 
            element={
              // <PrivateRoute>
                <Hospitals />
              // </PrivateRoute>
            } 
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
