// src/redux/priceSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { logout } from './authSlice';
import { toast } from 'react-toastify';

import posthog from 'posthog-js';
const initialState = {
  prices: [],
  status: 'idle',
  error: null,
};
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchPrices = createAsyncThunk(
  'price/fetchPrices',
  async ({ searchTerm, hospitalId, zipcode, limit = 100, offset = 0 }, { getState, rejectWithValue , dispatch}) => {
    try {
      const state = getState();
      // const token = state.auth.token;

      posthog.capture('procedure', {'procedure': searchTerm});
      const trimmedSearchTerm = searchTerm.trim();
      const params = {
        search_term: trimmedSearchTerm,
        limit,
        offset,
      };

      if (hospitalId) {
        params.hospital_id = hospitalId;
      }
      if (zipcode) {
        params.zipcode = zipcode; 
      }

      const response = await axios.get(`${backendUrl}/search/standard-charges`, {
        params,
        // headers: {
          // Authorization: `Bearer ${token}`,
        // },
      });

      return response.data.result;
    } catch (error) {
      console.log(error)
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please log in again.');
        dispatch(logout());
        return rejectWithValue('Unauthorized');
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const priceSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPrices.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.prices = action.payload; 
      })
      .addCase(fetchPrices.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default priceSlice.reducer;
