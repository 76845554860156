// src/redux/hospitalSystemsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  hospitalSystems: [],
  status: 'idle',
  error: null,
};

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchHospitalSystems = createAsyncThunk(
    'hospitalSystems/fetchHospitalSystems',
    async (_, { getState, rejectWithValue }) => {
      try {
        const state = getState();
        // const token = state.auth.token; 
  
        const response = await axios.get(`${backendUrl}/hospital-systems`, {
          headers: {
            // Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data.result;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
const hospitalSystemsSlice = createSlice({
  name: 'hospitalSystems',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitalSystems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHospitalSystems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.hospitalSystems = action.payload;
      })
      .addCase(fetchHospitalSystems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default hospitalSystemsSlice.reducer;
